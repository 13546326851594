import i18n from "i18next";
import { initReactI18next } from "react-i18next";


import translationEN from './locales/en/translation.json';
import translationNL from './locales/nl/translation.json';
import translationFR from './locales/fr/translation.json';
import translationDE from './locales/de/translation.json';
// the translations
// (tip move them in a JSON file and import them)

const resources = {
  nl: {
    translation: translationNL
  },
  en: {
    translation: translationEN
  },
  fr: {
      translation: translationFR
  },
  de: {
      translation: translationDE
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: window.localStorage.defaultLanguage,
    fallbackLng: "nl",
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;