import React from "react";
import uuid from 'react-uuid';

import StallItem from '../stallItem/StallItem';
import { useTranslation } from 'react-i18next';

import './StallList.scss';

import FlipMove from 'react-flip-move';

const StallList = (props) => {
    const { t } = useTranslation();

    //orders the stall list by given order
    switch (props.order) {
        case "descendingName":

            //oders the stall by id and them reorders them by the name
            props.stalls.sort((a, b) => (a.id < b.id) ? 1 : -1);

            props.stalls.sort((a, b) => (a.name.toLowerCase()
                                       < b.name.toLowerCase()) ? 1 : -1);

            break;
        case "ascendingLocation":
            //orders the stall by id
            //then orders them by name
            //then orders them location and location number
            props.stalls.sort((a, b) => (a.id > b.id) ? 1 : -1);

            props.stalls.sort((a, b) => (a.name.toLowerCase()
                                       > b.name.toLowerCase()) ? 1 : -1);

            props.stalls.sort((a, b) => a.location === b.location ?
                                      ((a.locationNr > b.locationNr) ? 1 : -1) :
                                       (a.location === null) ? 1 :
                                       (a.location === null) ? -1 :
                                       (a.location > b.location) ? 1 : -1);

            break;
        case "descendingLocation":
            //orders the stall by id
            //then orders them by name
            //then orders them location and location number
            props.stalls.sort((a, b) => (a.id < b.id) ? 1 : -1);

            props.stalls.sort((a, b) => (a.name.charAt(0).toUpperCase() + a.name.slice(1)
                                       < b.name.charAt(0).toUpperCase() + b.name.slice(1)) ? 1 : -1);

            props.stalls.sort((a, b) => a.location === b.location ?
                                      ((a.locationNr < b.locationNr) ? 1 : -1) :
                                       (a.location === null) ? 1 :
                                       (a.location === null) ? -1 :
                                       (a.location < b.location) ? 1 : -1);

            break;
        case "ascendingCategory":
            //orders the stall by id
            //then orders them by name
            //ten orders them by category
            props.stalls.sort((a, b) => (a.id > b.id) ? 1 : -1);

            props.stalls.sort((a, b) => (a.name.charAt(0).toLowerCase()
                                       < b.name.charAt(0).toLowerCase()) ? 1 : -1);

            props.stalls.sort((a, b) => (a.merkecategories[0] === undefined ? 1 :
                                         b.merkecategories[0] === undefined ? -1:
                                        (t('categoryTypes.' + a.merkecategories[0].name.toLowerCase())).toLowerCase()
                                      > (t('categoryTypes.' + b.merkecategories[0].name.toLowerCase())).toLowerCase()) ? 1 : -1);

            break;
        case "descendingCategory":
            //orders the stall by id
            //then orders them by name
            //ten orders them by category
            props.stalls.sort((a, b) => (a.id < b.id) ? 1 : -1);

            props.stalls.sort((a, b) => (a.name.charAt(0).toLowerCase()
                                        > b.name.charAt(0).toLowerCase()) ? 1 : -1);

            props.stalls.sort((a, b) => (a.merkecategories[0] === undefined ? 1 :
                                         b.merkecategories[0] === undefined ? -1 :
                                        (t('categoryTypes.' + a.merkecategories[0].name.toLowerCase())).toLowerCase()
                                      < (t('categoryTypes.' + b.merkecategories[0].name.toLowerCase())).toLowerCase()) ? 1 : -1);

            break;
        default:
            //oders the stall by id and them reorders them by the name
            props.stalls.sort((a, b) => (a.id > b.id) ? 1 : -1);

            props.stalls.sort((a, b) => (a.name.toLowerCase()
                                       > b.name.toLowerCase()) ? 1 : -1);
            break;
    }

    let allstalls = props.stalls;
    //filters the stalls by given category, if the category is selectCategory then it wont filter
    !('selectCategory' === props.filter) && (allstalls = (allstalls.filter(stall => stall.merkecategories.map(merke => merke.name === props.filter).includes(true))));

    //filters the stalls by given location, if the location is selectLocation then it wont filter
    !('selectLocation' === props.locationFilter) && (allstalls = allstalls.filter(stall => stall.location === props.locationFilter));


    //The enterance info for the stalls when they enter
    const enterAnimation = {
        from: { transform: 'scaleY(0)', transformOrigin: 'center top' },
        to: { transform: 'scaleY(1)', transformOrigin: 'center top' }
    };

     //The leave info for the stalls when they get filtered
    const leaveAnimation = {
        from: { transform: 'scaleY(1)', transformOrigin: 'center top' },
        to: { transform: 'scaleY(0)', transformOrigin: 'center top' }
    };


    //Renders all stalls with the enterance/exit animation and the duration
    return <div className="stall-list">
        <FlipMove appearAnimation={enterAnimation} enterAnimation={enterAnimation}
            leaveAnimation={leaveAnimation} duration={200} verticalAlignment={'top'}>
            {allstalls.map(stall => (
                <div className="stalls" key={stall.id}>
                    <StallItem key={uuid()} stall={stall} />
                </div>
            ))}
        </FlipMove>
    </div>;
};

export default StallList;