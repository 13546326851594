import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import {useLocation} from "react-router-dom";

import './SearchBox.scss';

const SearchBox = (props) => {
    
    const search = useLocation().search;

    const { t } = useTranslation();
    const [currentValue, setCurrentValue] = useState('');
    const setSearchValue = props.onchangefn;
    const [timedOut, setTimedOut] = useState('');
    const setDidChange = props.didChange;
    let defaultValue = 'loadsearch';
    let defaultInputName = 'zipcodes';
    let defaultBuffer = 1000;
    let defaultMinLength = 2;

    //set the current value if the url search isn't null
    useEffect(() => {
        const urlSearchVal = new URLSearchParams(search).get("search");
        if (urlSearchVal !== null) {
            setCurrentValue(urlSearchVal);
        }
    }, [search]);

    //change the defaultbuffer with a given buffer in the props
    if (typeof props.buffer === "number") {
        defaultBuffer = props.buffer;
    }

    //change the defaultMinLength with a given minLength in the props
    if (typeof props.minLength === "number") {
        defaultMinLength = props.minLength;
    }

    //change the inputName with a given inputName in the props
    if (props.inputName) {
        defaultInputName = props.inputName;
    }

    //change the defaultValue with a given defaultValue in the props
    if (typeof props.defaultValue === 'string') {
        defaultValue = props.defaultValue;
    }


    //update the searchvalue 
    const changeFn = (e) => {
        const val = e.target.value;
        if (timedOut) {
            clearTimeout(timedOut);
        }

        if (e.target.value.length > defaultMinLength) {
            setCurrentValue(() => {
                return val;
            });
            setTimedOut(() => {
                return setTimeout(function () {
                    setSearchValue(val);
                    setDidChange(true);
                }, defaultBuffer);
            });
        } else {
            setCurrentValue(() => {
                return val;
            });
            setSearchValue(defaultValue);
            setDidChange(true);
        }
    };


    //reset the current and search value when the times font is pressed resetting the search
    const onClearIconTap = () => {
        if (timedOut) {
            clearTimeout(timedOut);
        }
        setCurrentValue('');
        setSearchValue('');
        setDidChange(true);
    };


    //returns the searchbox
    return (
        <div className="searchbox-input">
            <FontAwesomeIcon icon={faSearch} className="3x search-icon" />
            <input type="text" placeholder={t('searchName')} name={defaultInputName} value={currentValue} onChange={changeFn} />
            {currentValue !== '' && <FontAwesomeIcon icon={faTimes} onClick={onClearIconTap} className="3x times-icon" />}
        </div>
    );
};

export default SearchBox