import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import VenueList from '../components/venuelist/VenueList';
import SearchBox from '../components/searchbox/SearchBox';
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';



const Search = (props) => {
    const search = useLocation().search;
    const { t } = useTranslation();
    const history = useHistory();
    const [searchValue, setSearchValue] = useState('');
    const [didChange, setDidChange] = useState(false);

    useEffect(() => {
        const urlSearchVal = new URLSearchParams(search).get("search");

        if (urlSearchVal) {
            setSearchValue(() => urlSearchVal);
        } else {
            setSearchValue('loadsearch');
        }

    }, [search]);

    const changeFn = (val) => {


        if (val !== 'loadsearch') {
            history.push({
                pathname: window.location.pathname,
                search: '?search=' + val
            });
        } else {
            history.push({
                pathname: window.location.pathname
            });
        }
        ;
        setSearchValue(val);
    };

    return (
        <div>
            <Helmet>
                <title>Fori: {t('planMyVisit')}</title>
            </Helmet>
            <div className="sticky">
                <div className="header-bar">
                    <div className="icon">
                        <Link to="/"><FontAwesomeIcon icon={faArrowLeft} className="2x" /></Link>
                    </div>
                    <span className="header-bar-text">{t('planMyVisit')}</span>
                    <div style={{ width: '0.875em' }} />
                </div>
                <div className="searchbox-container" >
                    <SearchBox onchangefn={changeFn} inputName="search" searchValue={searchValue} didChange={setDidChange} />
                </div>
            </div>
            <VenueList searchValue={searchValue} noresulttext={t('emptyTextSearch')} venues={props.venues} setVenues={props.setVenues} pathData={props.pathData} />

        </div>
    );
};


export default Search