import React from 'react';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

const DayTime = (props) => {

    const venue = props.venue;

    const { t } = useTranslation();

    const renderTime = () => {
        if (venue.startHour || venue.endHour) {
            const formattedStartHour = venue.startHour.substring(0, venue.startHour.length - 3);
            const formattedEndHour = venue.endHour.substring(0, venue.startHour.length - 3);
            return (<span className="hours">{formattedStartHour} - {formattedEndHour}</span>);
        }
    };

    const renderDay = () => {
        if (venue.venueType === 'MARKET') {
            var days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
            let daysText = [];
            days.forEach((day, index) => {
                if (venue[day]) {
                    if (daysText.length > 0) {
                        daysText.push(days[index]);
                    }
                    else {
                        daysText = [days[index]];
                    }
                }
            });

            const allDays = daysText.map((day, index) => <div className="day" key={index}>{t('days.' + day)}</div>)

            return allDays;
        } else if (venue.venueType === 'FAIR') {
            if (venue.firstUpcomingEvent) {
                return (
                    <span className="day">
                        <FontAwesomeIcon icon={faCalendar} />&nbsp;
                        <Moment format="DD/MM/YYYY" parse="YYYY-MM-DD">
                            {venue.firstUpcomingEvent.start}
                        </Moment> - <Moment format="DD/MM/YYYY" parse="YYYY-MM-DD">
                            {venue.firstUpcomingEvent.end}
                        </Moment>
                    </span>
                );
            }
        }
    };

    return (
        <div className="day-time">
            <div className="days">
                {renderDay(venue)}
            </div>
                {renderTime(venue)}
        </div>
    );
};

export default DayTime;