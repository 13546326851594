import React, {useState, useEffect} from 'react';
import moment from 'moment';

const UrlContext = React.createContext();

export const UrlContextProvider = (props) => {
    //const [baseUrl, setBaseUrl] = useState('http://localhost:8081');
    const [baseUrl, setBaseUrl] = useState('https://merke.be');
    const [executed, setExecuted] = useState(false);
    const [uniqueId, setUniqueId] = useState(window.localStorage.getItem('uniqueId'));

    const generateUniqueId = () => {
        return moment(new Date()).format('YYYYMMDDHHmmssSSS');
    };

    useEffect(() => {
      window.localStorage.setItem('uniqueId', uniqueId);
    },[uniqueId]);

    useEffect(() => {
        var type = 'production'; // local/production/dev
        switch (type) {
            case 'local':
                const port = '8080';
                setBaseUrl('http://' + window.location.hostname + ':' + port);
                break;
            case 'dev':
                setBaseUrl('https://dev.merke.be');
                break;
            default:
                break;
        }

        if (uniqueId === null) {
          setUniqueId(generateUniqueId());
        }

        setExecuted(() => true);
    }, [baseUrl, uniqueId]);

    return (
            <UrlContext.Provider value={{baseUrl: baseUrl, setBaseUrl: setBaseUrl, ex: executed, uniqueId: uniqueId}}>
                {props.children}
            </UrlContext.Provider>
            );
};

export default UrlContext;
