import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMapMarker} from '@fortawesome/free-solid-svg-icons';

const Location = (props) => {
    const {location, context, moved} = props;

    return (
            <span className={`location ${moved === true ? 'crossed' : ''}`}>
                <FontAwesomeIcon icon={faMapMarker} className="location-icon"/>
                <a rel="noreferrer" href={`http://maps.apple.com/?address=${location},${context.name},${context.zipcode}`} target="_blank">{location}</a>
            </span>
            );
};

export default Location;