import React, {useState, useEffect, useRef} from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import './SliderMenu.scss';

const SliderItem = (props) => {
    const childItem = useRef(null);
    const {item, index} = props;
    const {t} = useTranslation();
    
        
    useEffect(() => item.active && props.effectFn(childItem.current.offsetLeft, props.parentcontainer.current.clientWidth, childItem.current.clientWidth, props.index));
    
    return (
            <Link to={item.route}>
            <div ref={childItem}
                className={item.active === true ? 'active' : ''} 
                name={item.name} 
                onClick={(e) => {
                        const itemWidth = e.target.clientWidth;
                        const offsetLeft = e.target.offsetLeft;
                        const parentContainerWidth = props.parentcontainer.current.clientWidth;
                        props.clickFn(offsetLeft, parentContainerWidth, itemWidth, item.name, index);
                        props.setStartAnimation(false);
                    } }
                >
                {t(`menuChoices.${item.name}`)}
            </div>
            </Link>
            );
};

const SliderMenu = (props) => {
    const {setStartAnimation} = props;
    const DEFAULT_MARGIN = 16;

    const childContainer = useRef(null);
    const [sliderItems, setSliderItems] = useState([]);
    const [leftPosition, setLeftPosition] = useState(DEFAULT_MARGIN);
    const [parentHeight, setParentHeight] = useState(37);
    
    const location = useLocation();


    // NEED TO SET PARENT HIGH AFTER RENDERING


    useEffect(() => {
        
        const defaultItems = [
            {
                active: false,
                name: 'markets',
                route: '/'
            }, {
                active: false,
                name: 'info',
                route: '/info'
            }, {
                active: false,
                name: 'fairs',
                route: '/fairs'
            }
        ];

        const parsedItems = defaultItems.map((item) => {
            if (location.pathname === item.route) {
                return ({
                    ...item,
                    active: true
                });
            } else {
                return ({
                    ...item,
                    active: false
                });
            }
        });

        setSliderItems(parsedItems);
    }, [location]);

    useEffect(() => {
        setParentHeight(childContainer.current.clientHeight);
    }, [setParentHeight, parentHeight, childContainer]);

    const calcNewLeft = (offsetLeft, parentContainerWidth, itemWidth, index) => {
        let newLeft = DEFAULT_MARGIN;

        if (sliderItems.length - 1 === index) {
            newLeft = -(parentContainerWidth - window.screen.width + DEFAULT_MARGIN);
        } else if (offsetLeft !== 0) {
            newLeft = -offsetLeft + (window.screen.width / 2) - itemWidth / 2;
        }

        return newLeft;
    };
    
    const setNewLeft = (offsetLeft, parentContainerWidth, itemWidth, index) => {
        setLeftPosition(calcNewLeft(offsetLeft, parentContainerWidth, itemWidth, index));
    };

    const changeItem = (offsetLeft, parentContainerWidth, itemWidth, name, index) => {
        setSliderItems(prevState => prevState.map((item) => (item.name === name ? {...item, active: true} : {...item, active: false})));
    };
    

    const items = sliderItems.map((sliderItem, index) => {
        return <SliderItem key={index} item={sliderItem} index={index} clickFn={changeItem} setStartAnimation={setStartAnimation} effectFn={setNewLeft} parentcontainer={childContainer}/>;
    });

    return (
            <div id="navslider" className="navslider" style={{height: parentHeight}}>
                <div className="navslider-child-container" ref={childContainer} style={{left: leftPosition}}>
                    {items}
                </div>
            </div>
            );
};

export default SliderMenu