import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Languages from '../../containers/Languages';
import ZipCodes from '../../containers/ZipCodes';
import Toggle from 'react-toggle'
import logo from '../forilogo.svg'

import './Intro.scss';

const IntroNavigation = (props) => {
    const {t} = useTranslation();

    const increase = () => {
        props.setActiveIndex((prev) => {
            return prev + 1;
        });
    };

    const onNextClick = (e) => {
        if (props.index + 1 === props.views) {
            props.seenIntroFn((prev) => {
                window.localStorage.setItem('seenIntro', true);
                return true;
            });
        } else {
            if (typeof props.valid !== 'undefined') {
                if (props.valid) {
                    increase();
                }
            } else {
                increase();
            }

        }
    };

    const onBackClick = (e) => {
        props.setActiveIndex((prev) => {
            return prev - 1;
        });
    };
    const nextButton = <div className="intro-navigation-next-button" onClick={onNextClick}>{t('nextBtn')}</div>;
    const backButton = <div className="intro-navigation-back-button" onClick={onBackClick}><FontAwesomeIcon icon={faArrowLeft} className="3x search-icon"/></div>;

    return (
            <div className="intro-navigation-container">
                {props.index > 0 && backButton}{nextButton}
            </div>
            );
};

const IntroView = (props) => {
    return (
            <div className="intro-wrapper">
                <div className="intro-container-wrapper">
                <div className="intro-container-content">
                    {!props.noLogo && <img src={logo} className="fori-logo-intro-small" alt="Fori logo" />}
                    {props.children}
                    </div>
                </div>
                <IntroNavigation index={props.index} views={props.views} setActiveIndex={props.setActiveIndex}  seenIntroFn={props.seenIntroFn} valid={props.valid}/>  
            </div>
            );
};

const Intro = (props) => {
    const {t} = useTranslation();
    const [activeIndex, setActiveIndex] = useState(0);
    const [viewsLength, setViewsLength] = useState(0);
    const [isValid, setIsValid] = useState(false);

    const isValidFn = () => {
        return !isValid ? {display: "block"} : {display: "none"};
    };

    const views = [
        <IntroView index={activeIndex} setActiveIndex={setActiveIndex} views={viewsLength} noLogo seenIntroFn={props.seenIntroFn}>
            <img src={logo} className="fori-logo-intro" alt="Fori logo" />
            <p dangerouslySetInnerHTML={{__html: t('sidekickText')}}></p>
            <p className="bold">{t('selectLanguage')}</p>
            <Languages />
        </IntroView>,
        <IntroView index={activeIndex} setActiveIndex={setActiveIndex} views={viewsLength}  seenIntroFn={props.seenIntroFn}>
            <p dangerouslySetInnerHTML={{__html: t('safariInstall')}}></p>
            <p>
                <span dangerouslySetInnerHTML={{__html: t('safariInstallInstructions')}}></span> <FontAwesomeIcon icon={faPlusSquare} className="1x"/><span>{t('safariAddToHomescreen')}</span>
        </p>
        </IntroView>,
        <IntroView index={activeIndex} setActiveIndex={setActiveIndex} views={viewsLength}  seenIntroFn={props.seenIntroFn} valid={isValid}>
            <p dangerouslySetInnerHTML={{__html: t('cookies')}}></p>
            <div className="toggle-container">
            <Toggle 
                    name="cookiestoggle" 
                    checked={isValid} 
                    onChange={(e) => {
                        setIsValid(prev => !prev);
                    }}
                    //onToggle={}}
                    /*leftBackgroundColor="tomato"
                    rightBackgroundColor="#f8b065"
                    leftBorderColor="tomato"
                    rightBorderColor="#f8b065"
                    leftKnobColor="white"
                    rightKnobColor="white"*/
                    />
                <label htmlFor="cookiestoggle" className="cookietoggle-label" dangerouslySetInnerHTML={{__html: t('cookiesToggle')}}></label>
            </div>
            <p className="intro-warning" style={isValidFn()}>{t('cookiesWarning')}</p>
        </IntroView>,
        <IntroView index={activeIndex} setActiveIndex={setActiveIndex} views={viewsLength}  seenIntroFn={props.seenIntroFn}>
            <ZipCodes noPadding selectedzipcodes={props.zipcodes} setzipcodes={props.setzipcodes}/>
        </IntroView>,
        <IntroView index={activeIndex} setActiveIndex={setActiveIndex} views={viewsLength} seenIntroFn={props.seenIntroFn} >
            <h1 className="title fori-highlight-orange">{t('thankyou')}</h1>
            <p dangerouslySetInnerHTML={{__html: t('dataOptimized')}}></p>
        </IntroView>
    ];


    useEffect(() => {
        setViewsLength(views.length);
    }, [views.length]);

    return views[activeIndex];
};

export default Intro;