import React from "react";
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';
import "./Button.scss";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

const Button = (props) => {
    const {t} = useTranslation();
    let  {to} = props;

    return (
            <div className="button-container">
            <Link to={to}>
                <div className={`button ${props.fixed ? 'fixed' : ''}`}>
                    <FontAwesomeIcon icon={faCalendar} className="btn-icon" size="2x"/>
                    <span className="text">{t(`${props.text}`)}</span>
                </div>
            </Link>
            </div>
            );
};

export default Button;