import React from "react";

import { faSortAmountDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync }from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
//import { useParams } from "react-router-dom";

import './Filter.scss'

const Filter = (props) => {

    const { t } = useTranslation();

    //const { venueId } = useParams();

    let allCategories = [];
    let allLocations = [];

    const { type } = props;

    const getCategory = (category) => {
        let translation = t("categoryTypes." + category.toLowerCase()); 
        if (translation.includes('categoryTypes.')) {
            return category;
        } else {
            return translation;
        }
    }

    if (type === "FAIR") {
        //add all the categories names from the stalls to the array allCategories if
        //the category isn't null

        props.stalls.filter(stall => stall.merkecategories !== null).map(stall => stall.merkecategories.map(cat => allCategories.push(cat.name)));

        //filter allCategories by if the name isn't null
        allCategories = allCategories.filter(cat => cat !== null);

        //filter allCategories by if the name isn't undefined
        allCategories = allCategories.filter(cat => cat !== undefined);

        //allCategories get filled with only unique categories
        allCategories = Array.from(new Set(allCategories));

        //Sort the allCategories by name
        allCategories.sort((a, b) => a > b ? 1 : -1);

        //add selectCategory at position 0 of allCategories
        allCategories.unshift('selectCategory');

        //add the location from the stalls to the array allLocations if
        //the location isn't null
        props.stalls.filter(stall => stall.location !== null).map(stall => allLocations.push(stall.location));

        //allLocations get filled with only unique locations
        allLocations = Array.from(new Set(allLocations));

        //add selectLocation at position 0 of allLocations
        allLocations.unshift('selectLocation');


        //Returns the filter
        //the category filter consist out of allCategories and the sync icon sets the category filter to selectCategory
        //the location filter consist out of allLocations and the sync icon sets the location filter to selectLocation
        //the sort changes the order of the stalls based on the name, category or location alphabetically
        return <div className="stall-filter">
            <div className="stall-filter-settings">
                <div className="stall-filter-dropdownText">{t("categoryTypes.selectCategory")}</div>
                <div className="stall-filter-categorie">
                    <select className="stall-filter-dropDown" onChange={(e) => props.setFilter(e.target.value)} value={props.filter}>
                        {allCategories.map(category => <option className="stall-filter-dropDown-option" value={category} key={category}>{getCategory(category)}</option>)}
                    </select>
                    <FontAwesomeIcon icon={faSync} className="stall-itemUpdate-sort-logo" onClick={() => props.setFilter("selectCategory")} />
                </div>
            </div>
            {/*venueId === "96e092fc-d653-11e6-9b24-615897ebd569" &&
                <div className="stall-filter-settings">
                    <div className="stall-filter-dropdownText">{t('locationChoice')}</div>
                    <div className="stall-filter-categorie">
                        <select className="stall-filter-dropDown" onChange={(e) => props.setLocationFilter(e.target.value)} value={props.locationFilter}>
                            {allLocations.map(location => <option className="stall-filter-dropDown-option" value={location} key={location}>{location === 'selectLocation' ? t('selectLocation') : location}</option>)}
                        </select>
                        <FontAwesomeIcon icon={faSync} className="stall-itemUpdate-sort-logo" onClick={() => props.setLocationFilter("selectLocation")} />
                    </div>
                </div>
                */}
            <div className="stall-itemUpdate">
                <div className="stall-itemUpdate-sort-text">{t('orderByText')}</div>
                <div className="stall-itemUpdate-sort">
                    <select id="framework" className="dropDownOrder" onChange={(e) => props.setOrder(e.target.value)} value={t(props.order)}>
                        <option value="ascendingName">{t('orderBy.ascendByName')} </option>
                        <option value="descendingName">{t('orderBy.descendByName')}</option>
                        <option value="ascendingLocation">{t('orderBy.ascendByLocation')}</option>
                        <option value="descendingLocation">{t('orderBy.descendByLocation')}</option>
                        <option value="ascendingCategory">{t('orderBy.ascendByCategory')}</option>
                        <option value="descendingCategory" > {t('orderBy.descendByCategory')}</option>
                    </select>
                    <FontAwesomeIcon icon={faSortAmountDown} className="stall-itemUpdate-sort-logo" />
                </div>
            </div>
        </div>
    }
    else {
        //add all the categories names from the stalls to the array allCategories if
        //the category isn't null

        props.stalls.filter(stall => stall.merkecategories !== null).map(stall => stall.merkecategories.map(cat => allCategories.push(cat.type)));

        //filter allCategories by if the name isn't null
        allCategories = allCategories.filter(cat => cat !== null);

        //filter allCategories by if the name isn't undefined
        allCategories = allCategories.filter(cat => cat !== undefined);

        //allCategories get filled with only unique categories
        allCategories = Array.from(new Set(allCategories));

        //Sort the allCategories by name
        allCategories.sort((a, b) => a > b ? 1 : -1);

        //add selectCategory at position 0 of allCategories
        allCategories.unshift('selectCategory');


        //Returns the filter
        //the category filter consist out of allCategories and the sync icon sets the category filter to selectCategory
        //the sort changes the order of the vendors based on the name or category alphabetically

        return <div className="stall-filter">
            <div className="stall-filter-settings">
                <div className="stall-filter-dropdownText">{t("categoryTypes.selectCategory")}</div>
                <div className="stall-filter-categorie">
                    <select className="stall-filter-dropDown" onChange={(e) => props.setFilter(e.target.value)} value={props.filter}>
                        {allCategories.map(category => <option className="stall-filter-dropDown-option" value={category} key={category}>{t("categoryTypes." + category.charAt(0).toLowerCase() + category.slice(1))}</option>)}
                    </select>
                    <FontAwesomeIcon icon={faSync} className="stall-itemUpdate-sort-logo" onClick={() => props.setFilter("selectCategory")} />
                </div>
            </div>
            <div className="stall-itemUpdate">
                <div className="stall-itemUpdate-sort-text">{t('orderByText')}</div>
                <div className="stall-itemUpdate-sort">
                    <select id="framework" className="dropDownOrder" onChange={(e) => props.setOrder(e.target.value)} value={t(props.order)}>
                        <option value="ascendingName">{t('orderBy.ascendByNameVendor')} </option>
                        <option value="descendingName">{t('orderBy.descendByNameVendor')}</option>
                        <option value="ascendingCategory">{t('orderBy.ascendByCategory')}</option>
                        <option value="descendingCategory" > {t('orderBy.descendByCategory')}</option>
                    </select>
                    <FontAwesomeIcon icon={faSortAmountDown} className="stall-itemUpdate-sort-logo" />
                </div>
            </div>
        </div>
    }
};

export default Filter;
