const getValidUrl = (url) => {
    let newUrl = url.trim().replace(/\s/g, "");
    if (!/^https?:\/\//i.test(newUrl)) {
        return `http://${newUrl}`;
    }
    return newUrl;
};

export default class Helper {
    static getValidUrl(url) {
        return getValidUrl(url);
    }
}