import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import './Legal.scss';

const LegalLink = (props) => {
    return <li><a href={props.url} target="_blank" rel="noreferrer"><span className="legal-link-text">{props.text}<FontAwesomeIcon icon={faExternalLinkAlt} /></span><FontAwesomeIcon icon={faArrowRight} /></a></li>;
        };

        const Legal = () => {
            const {t} = useTranslation();
            return (
                <div className="legal-page" style={{padding: "0 1rem 1rem 1rem"}}>
                    <p>{t('legalLinksParaf')}</p>
                    <div className="legal-urls">
                        <ul>
                            <LegalLink url={t('privacyPolicyURL')} text={t('privacyPolicyText')} />
                            <LegalLink url={t('cookiePolicyUrl')} text={t('cookiePolicyText')} />
                            <LegalLink url={t('useragreementUrl')} text={t('useragreemenText')} />
                            <LegalLink url={t('datausageUrl')} text={t('datausageText')} />
                        </ul> 
                    </div>
                </div>);
        };

        export default Legal;