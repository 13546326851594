import React from "react";
import { Motion, spring } from "@serprex/react-motion";
import './AnimatedView.scss';

const AnimatedView = (props) => {

    const initialStyle = { opacity: 0, left: -50 };

    return (
        <Motion style={
            props.startAnimation
                ? { opacity: spring(1, { stiffness: 105, damping: 25 }), left: spring(0, { stiffness: 300, damping: 50 }) }
                : initialStyle}>
            {interpolatesStyles => (
                <div className="animation-frame" style={{ opacity: interpolatesStyles.opacity, left: interpolatesStyles.left }}>
                    {props.children}
                </div>
            )}

        </Motion>);
};

export default AnimatedView;