import React from "react";

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import './Articles.scss';

const ArticleBox = (props) => {
    const {titleStyled, imgUrl, id} = props.data;
    return ( <Link to={`/articles/${id}`}><div className="articlebox" style={{backgroundImage: `url(${imgUrl})`}}>
   <div className="articlebox-title"><span className="title" dangerouslySetInnerHTML={{__html: titleStyled}}></span><FontAwesomeIcon className="articlebox-icon" icon={faCaretRight}/></div>
    </div></Link>);
    };

const Articles = (props) => {
    const articlesList = props.articles.map(article => <ArticleBox key={article.id} data={article} />);

    return (
        <div className="article-list-container">
            {articlesList}
        </div>
    );
};

export default Articles;